<div fxLayout="row wrap">
  <div fxFlex="100" style="display: flex; justify-content: space-between; align-items: center;">
    <div fxFlex="50">
      <form class="d-flex row m-l-10" (ngSubmit)="applySearchBundle()">
        <mat-form-field>
          <mat-label>Search license bundle by name, modules or default issue </mat-label>
          <input matInput style="padding-bottom: 1px" type="text" [(ngModel)]="searchBundle" name="searchBundle" />
          <button *ngIf="searchBundle" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchBundle()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="m-l-10 d-flex align-items-center">
          <button type="submit" mat-raised-button color="primary" style="margin-right: 10px">
            Search
          </button>
        </div>
      </form>
    </div>
    <button mat-raised-button color="primary" (click)="addLicenseBundle(); getMasterLicense()">
      <mat-icon>post_add</mat-icon> Add License Bundle
    </button>
  </div>

  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="responsive-table">
      <mat-progress-bar
      mode="indeterminate"
      class="m-t-20"
      *ngIf="bundleloading"
    ></mat-progress-bar>
      <mat-table [dataSource]="dataSourceBundle" matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="modules">
          <mat-header-cell *matHeaderCellDef> Modules </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.apps }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="default_issue">
          <mat-header-cell *matHeaderCellDef> Default Issue </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.default_issue">
              <i class="fa fa-check-circle" style="color: green"></i>
            </ng-container>
            <ng-container *ngIf="!element.default_issue">
              <i class="fa fa-times-circle" style="color: red"></i>
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button
              mat-mini-fab
              color="primary"
              class="m-r-10"
              (click)="editBundle(element)"
              [disabled]="!element.id"
            >
              <i class="fa fa-pencil"></i>
            </button>
            <button
              mat-mini-fab
              color="warn"
              (click)="deleteBundle(element)"
              [disabled]="!element.id"
            >
              <i class="fa fa-trash"></i>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsBundle"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsBundle">
        </mat-row>
      </mat-table>
      <mat-paginator
        [length]="totalRows"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="pageChanged($event)"
        #MasterPagination
      ></mat-paginator>
    </div>
  </div>
</div>
<div fxLayout="row wrap">
  <div fxFlex="100">
    <h3>Master Licenses</h3>
    <mat-divider
      style="margin-top: 0; padding-top: 0"
      inset="true"
    ></mat-divider>
  </div>
  <div fxFlex="100">
    <p>
      All Master Licenses created by Test, are displayed below along with Expiry Date and Maximum Users Allowed.
    </p>
  </div>

  <div fxFlex="100" style="display: flex; justify-content: space-between">
    <div fxFlex="50">
      <form class="d-flex row m-l-10" (ngSubmit)="applySearch()">
        <mat-form-field>
          <mat-label>Search license by module, issue date, expiry date, max users, current users or status</mat-label>
          <input matInput style="padding-bottom: 1px" type="text" [(ngModel)]="searchMasterLicense" name="searchMasterLicense" />
          <button *ngIf="searchMasterLicense" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="m-l-10 d-flex align-items-center">
          <button type="submit" mat-raised-button color="primary" style="margin-right: 10px">
            Search
          </button>
        </div>
      </form>
    </div>
  </div>

  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="responsive-table">
      <mat-progress-bar
      mode="indeterminate"
      class="m-t-20"
      *ngIf="loading"
    ></mat-progress-bar>
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="module">
          <mat-header-cell *matHeaderCellDef> Module </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.different_name_format">
              {{ element.name }}
            </ng-container>
            <ng-container *ngIf="!element.different_name_format">
              {{ element.module.name }}
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="issue">
          <mat-header-cell *matHeaderCellDef> Issue Date </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.date_created | date : "dd/MM/yy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expiry">
          <mat-header-cell *matHeaderCellDef> Expiry Date </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.expiry">{{
              element.expiry | date : "dd/MM/yy"
            }}</ng-container
            ><ng-container *ngIf="!element.expiry">-</ng-container></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="max_users">
          <mat-header-cell *matHeaderCellDef> Max Users </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.maximum_issue">
              {{ element.maximum_issue }}
            </ng-container>
            <ng-container *ngIf="!element.maximum_issue"> - </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="current_users">
          <mat-header-cell *matHeaderCellDef> Current Users </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.assigned_licenses_count }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="checkActive(element.id) && element.id">
              <i class="fa fa-check-circle" style="color: green"></i>
            </ng-container>
            <ng-container *ngIf="!checkActive(element.id) && element.id">
              <i class="fa fa-times-circle" style="color: red"></i>
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="update_date">
          <mat-header-cell *matHeaderCellDef>
            Document Update Date
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.user_asset_document.date_updated }}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-mini-fab color="primary" (click)="uploadDoc(element)" *ngIf="!element.user_asset_document.doc_url">
              <i class="fa fa-cloud-upload"></i>
            </button>          
            <button mat-mini-fab color="primary" (click)="updateUploadDoc(element)" *ngIf="element.user_asset_document.doc_url">
              <i class="fa fa-cloud-download"></i>
            </button>          
          </td>
        </ng-container> -->

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef style="display: flex; justify-content: end;"> Actions </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element" style="display: flex; justify-content: end;">
            <button mat-mini-fab color="primary" (click)="uploadDoc(element)" *ngIf="!element.user_asset_document.doc_url">
              <i class="fa fa-cloud-upload"></i>
            </button>          
            <button mat-mini-fab color="accent" (click)="updateUploadDoc(element)" *ngIf="element.user_asset_document.doc_url">
              <i class="fa fa-cloud-download"></i>
            </button>          
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
      </mat-table>
      <mat-paginator
        [length]="secondTotalRows"
        [pageIndex]="secondCurrentPage"
        [pageSize]="secondPageSize"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="secondPageChanged($event)"
        #licensePagination
      ></mat-paginator>
    </div>
  </div>
</div>
