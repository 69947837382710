import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-headset-issue-modal',
  templateUrl: './headset-issue-modal.component.html',
  styleUrls: ['./headset-issue-modal.component.scss']
})
export class HeadsetIssueModalComponent implements OnInit {

  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<HeadsetIssueModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  { 
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this._dialogRef.close({data: this.data});
  }

  onChange(event: any){
    this.data.issue.address_id = event;
    var addy = this.data.user_addresses.filter((x:any) => x.id === event)[0]
    this.data.issue.name = addy.name
    this.data.issue.address_1 = addy.address_1
    this.data.issue.address_2 = addy.address_2
    this.data.issue.address_3 = addy.address_3
    this.data.issue.address_4 = addy.address_4
    this.data.issue.postcode = addy.postcode
    this.data.issue.country = addy.country

  }
  disableHeadset(headset: any) {
  // Check if any headset.used ID is in assignedHeadsets
  return headset.used.some((usedId: number) => this.data.assignedHeadsets.includes(usedId)); 
  }
}
