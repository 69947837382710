<div fxLayout="row" fxLayoutAlign="center start">
  <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
    <mat-card
      class="mat-card-top notes-app"
      [ngClass]="{ 'notes-app-open': sidePanelOpened }"
    >
      <mat-sidenav-container
        [ngClass]="{
          'side-panel-opened': sidePanelOpened,
          'side-panel-closed': !sidePanelOpened
        }"
      >
        <mat-sidenav
          #noteslist
          [mode]="isOver() ? 'over' : 'side'"
          [opened]="!isOver()"
          (open)="sidePanelOpened = true"
          (close)="sidePanelOpened = false"
        >
          <mat-toolbar class="no-shadow">
            <div fxLayout="row" fxFlex="100">
              <mat-form-field>
                <mat-label>Search Notes</mat-label>
                <input matInput [(ngModel)]="searchText" />
              </mat-form-field>
            </div>
          </mat-toolbar>

          <mat-nav-list>
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="loading"
            ></mat-progress-bar>
            <ng-container *ngIf="notes.length > 0; else elseBlock">
              <mat-list-item
                *ngFor="let note of notes | filter : searchText"
                class="notes-item"
                (click)="onSelect(note)"
                [class.selected]="note === selectedNote"
              >
                <div class="note d-flex">
                  <div>
                    <span class="note-color bg-info"></span>
                    <span class="note-title">{{ note.note }}</span>
                    <small class="note-time" style="font-size: 8px"
                      >{{ note.added_by.first_name }} on
                      {{
                        note.date_created | date : "dd/MM/yy HH:mm:ss"
                      }}</small
                    >
                  </div>
                  <span class="remove-note"
                    ><button
                      (click)="deleteNote(note)"
                      *ngIf="note.saved"
                      style="border-width: 0; background-color: transparent"
                    >
                      <i class="fa fa-trash text-danger"></i></button
                  ></span>
                </div>
              </mat-list-item>
            </ng-container>
            <ng-template #elseBlock>
              <p class="m-b-0 text-center font-18 font-bold m-t-40">
                No Notes Found
              </p>
            </ng-template>
          </mat-nav-list>
          <ng-container class="mat-list-pagination">
            <mat-paginator
              #paginator
              [length]="totalRows"
              [pageIndex]="currentPage"
              [pageSize]="pageSize"
              [pageSizeOptions]="[10, 25, 50]"
              hidePageSize="true"
              (page)="pageChanged($event)"
            ></mat-paginator>
          </ng-container>
        </mat-sidenav>
        <mat-toolbar class="no-shadow">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
            <button
              (click)="noteslist.toggle(); sidePanelControl()"
              class="mr-1"
              mat-icon-button
            >
              <mat-icon>short_text</mat-icon>
            </button>
            <div fxFlex align="end" style="text-align: right">
              <button
                mat-raised-button
                color="primary"
                (click)="addNoteClick()"
                *ngIf="!selectedNote"
              >
                <mat-icon>post_add</mat-icon> Add Note
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="addNoteClick()"
                *ngIf="selectedNote && selectedNote.saved"
              >
                <mat-icon>post_add</mat-icon> Add Note
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="saveNoteClick()"
                *ngIf="selectedNote && !selectedNote.saved"
              >
                <mat-icon>saved</mat-icon> Save Note
              </button>
            </div>
          </div>
        </mat-toolbar>
        <mat-card-content class="chat-middle-box">
          <div class="" *ngIf="selectedNote">
            <textarea
              matInput
              [(ngModel)]="selectedNote.note"
              rows="15"
              class="note-content"
              [readonly]="selectedNote.saved"
              (blur)="autoSaveNotes()"
              (keydown.enter)="autoSaveNotes()"
            ></textarea>
          </div>
        </mat-card-content>
      </mat-sidenav-container>
    </mat-card>
  </div>
</div>
