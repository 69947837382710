<div fxLayout="row wrap" style="margin-top: 10px">
  <div fxLayout="row" fxFlex="50" style="text-align: left; align-items: center">
    <div fxFlex="100">
      <form class="d-flex row m-l-10" (ngSubmit)="applySearch()">
        <mat-form-field>
          <mat-label>Search group by name, manager or email</mat-label>
          <input
            matInput
            style="padding-bottom: 1px"
            type="text"
            [(ngModel)]="searchGroup"
            name="searchGroup"
          />
          <button
            *ngIf="searchGroup"
            type="button"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="m-l-10 d-flex align-items-center">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            style="margin-right: 10px"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  </div>
  <div
    fxFlex="50"
    style="
      text-align: right;
      align-items: center;
      display: flex;
      justify-content: flex-end;
    "
    *ngIf="!isRoleLearner"
  >
    <button
      mat-raised-button
      color="primary"
      (click)="mergeGroup()"
      [disabled]="loading || selection.selected.length < 2"
      style="margin-right: 10px"
    >
      Merge Group
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="moveUser()"
      [disabled]="loading || !CompanyGroup?.length"
      style="margin-right: 10px"
    >
      Move Users
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="addGroup()"
      [disabled]="loading || !AvailableUser?.length"
    >
      <mat-icon>group_add</mat-icon>
      Add Group
    </button>
  </div>
  <div fxFlex="100">
    <mat-progress-bar
      mode="indeterminate"
      class="m-t-20"
      *ngIf="loading"
    ></mat-progress-bar>
    <div class="table-responsive">
      <table
        mat-table
        [dataSource]="!loading ? dataSource : [] || []"
        matSort
        class="table employee-list no-wrap"
      >
        <ng-container matColumnDef="select" *ngIf="!isRoleLearner">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            ></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
              [disabled]="checkCan_edit(row)"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <div>
                <p class="font-medium m-b-0 m-t-0">{{ element.group_name }}</p>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="groupManager">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Group Manager
          </th>
          <td mat-cell *matCellDef="let element">
            <app-user-card
              [userDetail]="element.manager"
              [optionKey]="'first_name'"
            ></app-user-card>
          </td>
        </ng-container>

        <ng-container matColumnDef="userCount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Count of User
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.user_count || 0 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Created at</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date_created | date : "dd/MM/yy HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action" *ngIf="!isRoleLearner">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <button
              mat-mini-fab
              color="primary"
              class="m-r-10"
              [disabled]="!element.can_edit"
              (click)="openEditDialog(element)"
            >
              <i class="fa fa-pencil"></i>
            </button>
            <button
              mat-mini-fab
              color="warn"
              (click)="deleteGroup(element)"
              [disabled]="!element.can_edit"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <!-- <p
        *ngIf="!dataSource?.data.length && !loading"
        style="display: flex; justify-content: center"
      >
        No group exist
      </p> -->
        <mat-paginator
          [length]="totalRows"
          [pageIndex]="currentPage"
          [pageSize]="pageSize"
          [pageSizeOptions]="[10, 25, 50]"
          (page)="pageChanged($event)"
          #paginator
        ></mat-paginator>
    </div>
  </div>
</div>

<app-loader *ngIf="isLoading" [size]="'large'"></app-loader>
